



























































































































































































































import { Component, Vue } from "vue-property-decorator";
import cardBox from "@/components/card-box/index.vue";
import pagination from "@/components/pagination/index.vue";
import { PageNum } from "@/tool/interface-index";
import {
  getTestPaper,
  delTestPaper,
  setTestPaper,
  getTestPaperList,
  getTestPaperGroupGradeOptionList,
  copyForTestPaper,
} from "@/api/testPaper";
import {
  TestPaperGroup,
  TestPaperGroupGradeOption,
  TestPaper,
  TestPaperItem,
} from "@/tool/_class";
import { UserModule } from "@/store/modules/user";
import TestPaperDialog from "./test-paper-dialog.vue";
import * as _ from "lodash";

@Component({
  name: "TestPaperListPage",
  components: {
    cardBox,
    pagination,
    TestPaperDialog,
  },
})
export default class extends Vue {
  private multipleSelection: TestPaperItem[] = [];

  private query: {
    keyWords: string;
    gradeNo?: string;
    grade?: string;
    term?: string;
    testPaperName?: string;
    testPaperIds: string[];
  } = {
    gradeNo: "",
    keyWords: "",
    testPaperIds: [],
  };

  private pageNum: PageNum = {
    totalPage: 0,
    curPage: 1,
    pageSize: 10,
  };
  private grades: TestPaperGroupGradeOption[] = [];
  private tableData: TestPaperItem[] = [];

  private get disabledOperation(): boolean {
    console.log(`roles:${UserModule.roles}`);
    if (this.$route.meta.roles) {
      let roled: boolean = false;
      for (let i = 0; i < this.$route.meta.roles.length; i++) {
        roled =
          roled ||
          UserModule.roles.some((item) => item == this.$route.meta.roles[i]);
        if (roled) {
          break;
        }
      }
      if (!roled) {
        return true;
      }
      console.log(`meta.roles:${this.$route.meta.roles},roled:${roled}`);

      if (
        UserModule.roles.some(
          (item) => item == "admin" || item == "platformAdmin"
        )
      ) {
        return false;
      }

      /*
      return (
        UserModule.roles.findIndex((role: string) => {
          return role.toLowerCase() == "teacher";
        }) >= 0
      );
      */
    }
    return true;
  }

  private copyStdAnsDestTestPaper!: TestPaper;

  private async handleTestPaperConfirmed(testPapers: TestPaper[]) {
    await copyForTestPaper({
      fromTestPaperFileName: testPapers[0].testPaperFileName,
      toTestPaperFileName: this.copyStdAnsDestTestPaper.testPaperFileName,
    });

    await this.$confirm("导入完成，是否现在去编辑标答？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    });
    this.toTestAnswer(
      this.copyStdAnsDestTestPaper.testPaperFileName,
      this.copyStdAnsDestTestPaper.gradeNo,
      this.copyStdAnsDestTestPaper.str02
    );
  }

  private doCopyStandardAnswerFromClick(testPaper: TestPaper) {
    this.copyStdAnsDestTestPaper = testPaper;
    (this.$refs.testPaperDialog as TestPaperDialog).show({}, []);

    //
  }
  private toTestAnswer(fileName: string, gradeNo: string, str02: string) {
    this.$router.push({
      path: "testAnswer",
      query: {
        gradeNo,
        fileName,
        str02,
      },
    });
  }
  private delTest(testId: string) {
    this.$confirm("删除当前记录, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        delTestPaper(testId).then((res: any) => {
          if (res.code === 0) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.refPageList();
          }
        });
      })
      .catch(() => {
        //
      });
  }
  private editorTest(testPaperId: string, actionType: string) {
    this.$router.push({
      path: "testEditor",
      query: {
        testPaperId: testPaperId,
        type: actionType,
      },
    });
  }
  private doUseAuthorizeClick(testPaperId: string) {
    this.$router.push({
      path: "testPaperUseAuth",
      query: {
        testPaperIds: [testPaperId],
        useAuthTo: "right",
      },
    });
  }
  private gradeFormatter(row: any, column: any) {
    const idx = this.grades.findIndex((item: any) => {
      return item.gradeNo == row.gradeNo;
    });
    if (idx >= 0) {
      return this.grades[idx].grade;
    } else {
      return row.grade;
    }
  }
  private standardAnswerFormatter(row: any, column: any) {
    if (row.questionCount) {
      return `${row.standardAnswerCount} / ${row.questionCount}`;
    } else {
      return "0";
    }
  }

  private doClearTestPaperIdsClick() {
    if (this.$route.query.testPaperIds) {
      (this.$route.query.testPaperIds as string[]).splice(
        0,
        (this.$route.query.testPaperIds as string[]).length
      );
      this.refPageList();
    }
  }

  private async refreshHashCode(papers: TestPaperItem[]) {
    const tasks: any[] = [];
    for (let i = 0; i < papers.length; i++) {
      const { data } = await getTestPaper(papers[i].testPaperId);
      data.testPaperFileHashCode = papers[i].paperFileInfo.hashCode;
      tasks.push(setTestPaper(data));
    }
    const resps = await Promise.all(tasks);
    if (resps.some((resp) => resp.code != 0)) {
      //出错
    } else {
      papers.forEach(
        (paper) => (paper.testPaperFileHashCode = paper.paperFileInfo.hashCode)
      );
    }
  }

  private doRefreshHashCodeClick() {
    if (this.multipleSelection.length == 0) {
      this.$message({
        message: "请先选择试卷。",
        type: "warning",
      });
      return;
    }
    this.refreshHashCode(this.multipleSelection);
  }

  private queryTestPaperClick() {
    this.pageNum.curPage = 1;
    this.refPageList();
  }
  private upDataPage() {
    this.refPageList();
  }
  private loading: boolean = false;
  private async refPageList() {
    try {
      this.loading = true;
      this.query.testPaperIds = _.uniq(
        (this.$route.query.testPaperIds as string[]) || []
      ).filter((item) => item != "");
      const res = await getTestPaperList({
        testPaperIds: this.query.testPaperIds,
        gradeNo: this.query!.gradeNo || "",
        keyWords: this.query!.keyWords || "",
        mergePaperFileInfo: true,
        curPage: this.pageNum.curPage,
        pageSize: this.pageNum.pageSize,
      });
      this.tableData = res.data.items;
      this.pageNum.totalPage = res.data.totalPage;
      this.pageNum.curPage = res.data.curPage;
      this.pageNum.pageSize = res.data.pageSize;
    } finally {
      this.loading = false;
    }
  }

  handleSelectionChange(val: TestPaperItem[]) {
    this.multipleSelection = val;
  }

  doUseAuthToClick(useAuthTo: string) {
    if (this.multipleSelection.length == 0) {
      this.$message({
        message: "请先选择试卷。",
        type: "warning",
      });
      return;
    }
    let testPaperIds: string[] = [];
    for (let i = 0; i < this.multipleSelection.length; i++) {
      testPaperIds.push(
        (this.multipleSelection[i] as any).testPaperId as string
      );
    }
    console.log(`testPaperIds:${JSON.stringify(testPaperIds)}`);
    this.$router.push({
      path: "testPaperUseAuth",
      query: {
        testPaperIds: testPaperIds,
        useAuthTo: useAuthTo,
      },
    });
  }

  private async __init() {
    this.grades.splice(0, this.grades.length);
    const { data } = await getTestPaperGroupGradeOptionList();
    data.items.forEach((element: TestPaperGroupGradeOption) => {
      this.grades.push(element);
    });

    console.log(`grades: ${JSON.stringify(this.grades)}`);
  }

  activated() {
    this.__init();
    this.refPageList();
  }
  mounted() {}
}
